import $ from 'jquery'

const Selector = {
  DATA_TOOLTIP   : '[data-tooltip]',
  DATA_POPOVER   : '[data-popover]'
}

function initTooltip() {
  $(Selector.DATA_TOOLTIP).tooltip({
    html: true,
    container: 'body',
    title() {
      const $this = $(this)
      return $this.data('tooltip') ? $this.data('tooltip') : $this.attr('title')
    }
  })
}

function initPopover() {
  $(Selector.DATA_POPOVER).popover({
    html: true,
    container: 'body',
    trigger: 'hover'
  })
}

$(document).ready(initTooltip)
$(document).ready(initPopover)

export default {}
